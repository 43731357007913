<template>
  <div class="auth-wrapper auth-v2 login-bg">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo d-flex align-items-center animate__animated animate__fadeInLeft" target="_blank" href="http://www.cloudxin.cn">
        <aiot-logo />
        <span class="brand-text text-white text-center font-medium-5">
          {{ $t('name') }}
        </span>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center">
        <lottie style="width: 900px;height: 900px;" :options="defaultOptions"/>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <!--class="d-flex align-items-center auth-bg px-2 p-lg-5"-->
      <b-col
        lg="4"
        class="d-flex align-items-center px-2 p-lg-2 animate__animated animate__fadeInRight"
      >
        <b-card class="m-1">
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-3 mx-auto"
          >
            <div class="d-flex justify-content-end">
              <dark-toggler2/>
            </div>
            <div
              class="text-center x-text-bold mb-3 font-large-1 text-primary"
            >
              {{ $t('welcome') }}
            </div>

            <b-alert
              variant="primary"
              show
            >
              <div class="alert-body font-small-2">
                <p>
                  <small class="mr-50">
                    {{ $t('welinfo') }}</small>
                </p>
              </div>
              <!--<feather-icon-->
              <!--v-b-tooltip.hover.left="'This is just for ACL demo purpose'"-->
              <!--icon="HelpCircleIcon"-->
              <!--size="18"-->
              <!--class="position-absolute"-->
              <!--style="top: 10; right: 10;"-->
              <!--/>-->
            </b-alert>
            <!-- form -->
            <validation-observer
              ref="loginForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="login"
              >
                <!-- 租户 -->
                <!--<b-form-group-->
                  <!--label-class="h4 x-text-bold"-->
                  <!--label-for="login-account"-->
                <!--&gt;-->
                  <!--<validation-provider-->
                    <!--#default="{ errors }"-->
                    <!--name="Account"-->
                    <!--vid="account"-->
                    <!--rules="required"-->
                  <!--&gt;-->
                    <!--<b-input-group-->
                      <!--class="input-group-merge"-->
                      <!--:class="errors.length > 0 ? 'is-invalid':null"-->
                    <!--&gt;-->
                      <!--<b-input-group-prepend is-text>-->
                        <!--<feather-icon icon="KeyIcon" />-->
                      <!--</b-input-group-prepend>-->
                      <!--<b-form-input-->
                        <!--id="login-tenantId"-->
                        <!--v-model="tenantId"-->
                        <!--:state="errors.length > 0 ? false:null"-->
                        <!--name="login-tenantId"-->
                        <!--size="lg"-->
                        <!--:placeholder="$t('placeholderTenantId')"-->
                      <!--/>-->
                    <!--</b-input-group>-->
                    <!--<small-->
                      <!--v-if="errors[0]"-->
                      <!--class="text-danger"-->
                    <!--&gt;租户不能为空</small>-->
                  <!--</validation-provider>-->
                <!--</b-form-group>-->
                <!-- 账户 -->
                <b-form-group
                  label-class="h4 x-text-bold"
                  label-for="login-account"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Account"
                    vid="account"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="UserIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="login-account"
                        v-model="username"
                        :state="errors.length > 0 ? false:null"
                        name="login-account"
                        size="lg"
                        class="x-clear-input"
                        :placeholder="$t('placeholderAccount')"
                      />
                    </b-input-group>
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >账号不能为空</small>
                  </validation-provider>
                </b-form-group>

                <!-- forgot password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <!--<label for="login-password" class="h4 x-text-bold" >{{ $t('password') }}</label>-->
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-input-group-prepend is-text>
                        <feather-icon icon="LockIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge x-clear-input"
                        :type="passwordFieldType"
                        name="login-password"
                        size="lg"
                        :placeholder="$t('placeholderPassword')"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >密码不能为空</small>
                  </validation-provider>
                </b-form-group>

                <!-- vcode -->
                <b-form-group
                  label-class="h4 x-text-bold"
                  label-for="login-vcode"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="vcode"
                    vid="vcode"
                    rules="required"
                  >
                    <div class="d-flex align-content-between align-items-center">
                      <b-input-group
                        class="input-group-merge"
                        :class="errors.length > 0 ? 'is-invalid':null"
                      >
                        <b-input-group-prepend is-text>
                          <feather-icon icon="MailIcon" />
                        </b-input-group-prepend>
                        <b-form-input
                          id="login-vcode"
                          v-model="code"
                          :state="errors.length > 0 ? false:null"
                          name="login-vcode"
                          size="lg"
                          :placeholder="$t('placeholderVcode')"
                        />
                      </b-input-group>
                      <b-img
                        class="ml-1 vcode"
                        :src="codeImage"
                        @click="refreshVcode"
                      />
                    </div>
                    <small
                      v-if="errors[0]"
                      class="text-danger"
                    >验证码不能为空</small>
                  </validation-provider>
                </b-form-group>
                <!-- checkbox -->
                <div v-if="false" class="d-flex justify-content-between align-items-center">
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    {{ $t('rememberMe') }}
                  </b-form-checkbox>
                  <!--<b-link :to="{name:'auth-forgot-password'}">-->
                    <!--<small class="x-text-bold">{{ $t('forgetPassword') }}</small>-->
                  <!--</b-link>-->
                </div>

                <!-- submit buttons -->
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="primary"
                  class="mt-2"
                  block
                  pill
                  :disabled="invalid"
                >
                  {{ $t('login') }}
                </b-button>
              </b-form>
            </validation-observer>
            <p class="text-right mt-2">
              <!--<span>{{ $t('hasNoAccount') }}</span>-->
              <!--<b-link :to="{name:'auth-register'}">-->
                <!--<span class="font-weight-bolder">&nbsp;{{ $t('register') }}</span>-->
              <!--</b-link>-->
            </p>
          </b-col>
        </b-card>
      </b-col>
      <!-- /Login-->
    </b-row>
    <div class="position-fixed x-w-100 x-bottom-0 animate__animated animate__fadeInUp copyright">
      <div class="d-flex justify-content-center">
        <p class="clearfix mb-0">
        <span class="float-md-left d-block d-md-inline-block mt-25">
          Copyright  © 2020
          <b-link
            class="ml-25"
            href="http://www.cloudxin.ltd"
            target="_blank"
          >云信科技</b-link>
          <span class="d-none d-sm-inline-block ml-25">版权所有</span>
          <b-link
            class="ml-1"
            href="https://beian.miit.gov.cn"
            target="_blank"
          >苏ICP备20020189号-2</b-link>
        </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
// import axios from 'axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AiotLogo from '@core/layouts/components/Logo.vue'
import DarkToggler2 from '@core/layouts/components/app-navbar/components/DarkToggler2.vue'
import * as animationData from '@/assets/lottie/login6.json'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BImg, BForm, BButton, BAlert, VBTooltip, BFormCheckbox,
  BInputGroupPrepend, BCard,
  // BCardText,
} from 'bootstrap-vue'
import { required, email, vcode } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import { getCaptcha } from '@/api/auth/auth'
import { loginByUsername } from '@/api/auth/login'
// import website from '@/config/website'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    // BCardText,
    BImg,
    BForm,
    BButton,
    BAlert,
    AiotLogo,
    ValidationProvider,
    ValidationObserver,
    BInputGroupPrepend,
    DarkToggler2,
    BCard,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      username: '',
      tenantId: '000000',
      // 账号类型
      type: 'account',
      code: undefined,
      codeImage: undefined,
      verifyKey: undefined,
      // sideImg: require('@/assets/images/pages/login-v2.svg'),
      sideImg: require('@/assets/images/pages/bg-dark-login.png'),

      // validation rules
      required,
      email,
      vcode,
      defaultOptions: { animationData: animationData.default },
      // website: website,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (this.$store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/bg-dark-login.png')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  created() {
    this.refreshVcode()
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          loginByUsername(this.tenantId, this.username, this.password, this.type, this.verifyKey, this.code).then(async response => {
            let userData = response.data
            await this.$store.dispatch('user/setUserInfo', userData)
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `${this.$t('Welcome')} ${userData.user_name}`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: this.$t('WelcomeContent'),
              },
            })
            this.$router.replace(getHomeRouteForLoggedInUser(userData.roleName))
          }).catch(() => {
            this.refreshVcode()
          })
        }
      })
    },
    refreshVcode() {
      getCaptcha().then(res => {
        this.codeImage = res.data.image
        this.verifyKey = res.data.key
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  @import '@core/scss/vue/pages/page-auth.scss';
  @import '@core/scss/base/components/_variables-dark.scss'; // Bootstrap includes
  .vcode{
    height: $input-height;
    width: 40%;
    //padding: $input-padding-y 0;
    //padding: ($input-padding-y/2) 0;
  }
</style>
