import request from '@/auth/jwt/useJwt'
import md5 from 'js-md5'

export function getVcode() {
  return request.axiosIns({
    url: '/authorize/captcha/image?width=160&height=40',
    method: 'get',
  })
}
export function loginByUsername(tenantId, username, password, type, key, code) {
  return request.axiosIns({
    url: '/apollo-auth/oauth/token',
    method: 'post',
    headers: {
      'Tenant-Id': tenantId,
      'Dept-Id': '',
      'Role-Id': '',
      'Captcha-Key': key,
      'Captcha-Code': code,
    },
    params: {
      tenantId,
      username,
      password: md5(password),
      grant_type: 'captcha',
      scope: 'all',
      type,
    },
  })
}
