import request from '@/auth/jwt/useJwt'

export function getVcode() {
  return request.axiosIns({
    url: '/authorize/captcha/image?width=160&height=40',
    method: 'get',
  })
}

export function getCaptcha() {
  return request.axiosIns({
    url: '/apollo-auth/oauth/captcha',
    method: 'get',
    noAuth: true,
  })
}

export const _ = null
